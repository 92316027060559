import { Component, OnInit } from '@angular/core';
import { HeaderStateService } from '../../headers/services/header-state.service';
import { InformationService } from '../services/information-service';
import { DataProtectionModel } from '../model/data-protection.model';

@Component({
  templateUrl: './data-protection.component.html',
  styleUrl: './data-protection.component.scss',
})
export class DataProtectionComponent implements OnInit {
  public dataProtectionModel?: DataProtectionModel;

  constructor(
    private headerStateService: HeaderStateService,
    private informationService: InformationService
  ) {
    this.headerStateService.setDetailHeader('Datenschutz');
  }

  ngOnInit(): void {
    this.loadText();
  }

  loadText() {
    this.informationService.getDataProtection().subscribe({
      next: (value) => value.text,
    });
  }
}
