import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from '../../../../environments/environment';
import { DataProtectionModel } from '../model/data-protection.model';
import { ImprintModel } from '../model/imprint.model';

@Injectable({
    providedIn: 'root'
})

export class InformationService {
    constructor(
        private httpClient: HttpClient
    ) { }

    getImprint(): Observable<ImprintModel> {
        return this.httpClient.get<ImprintModel>(`${environment.api}/information/imprint`);
    }

    getDataProtection(): Observable<DataProtectionModel> {
        return this.httpClient.get<DataProtectionModel>(`${environment.api}/information/data-protection`);
    }
}