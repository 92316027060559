import { Component } from '@angular/core';
import { HeaderStateService } from '../main/headers/services/header-state.service';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrl: './inbox.component.scss'
})
export class InboxComponent {
  constructor(private headerStateService: HeaderStateService) {
    this.headerStateService.setDetailHeader('Inbox', 'News und Benachrichtigunen');
  }
}