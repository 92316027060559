import { Component } from '@angular/core';
import { HeaderStateService } from '../main/headers/services/header-state.service';

@Component({
  selector: 'app-know-how',
  templateUrl: './know-how.component.html',
  styleUrl: './know-how.component.scss'
})
export class KnowHowComponent {
  constructor(private headerStateService: HeaderStateService) {
    this.headerStateService.setDetailHeader('Know-how', 'Dokumente und mehr');
  }
}