import { Component, OnInit } from '@angular/core';
import { HeaderStateService } from '../main/headers/services/header-state.service';
import { UserAuthorizationService } from '../security/user-authorization.service';
import { AssignmentsOverviewModel } from '../assignments/models/assignments-overview.model';
import { AssignmentEventType } from '../shared/enums/AssignmentEventType.enums';
import { AssignmentStateService } from '../assignments/services/assignment-state.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
    assigmentsOverviewModel?: AssignmentsOverviewModel[] = [];
    assignmentEventType = AssignmentEventType;
    isLoggedIn?: boolean;

    constructor(
        private headerStateService: HeaderStateService,
        private authService: UserAuthorizationService,
        public assignmentsStateService: AssignmentStateService
    ) {
        this.headerStateService.showDefaultHeader();
    }

    ngOnInit(): void {
        this.authService.isLoggedIn().then((x) => {
            this.isLoggedIn = x;
            if (this.isLoggedIn) {
                this.assignmentsStateService.loadtopThreeNextAssignments();
                this.assignmentsStateService.loadtopThreeOpenAssignments();
            };
        });
    }

    login() {
        this.authService.startAuthentication();
    }
}
