import { Component } from '@angular/core';
import { HeaderStateService } from '../../headers/services/header-state.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss'
})
export class FaqComponent {
  constructor(private headerStateService: HeaderStateService) {
    this.headerStateService.setDetailHeader('FAQ');
  }
}
