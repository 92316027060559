import { Component } from '@angular/core';
import { HeaderStateService } from '../../headers/services/header-state.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrl: './help.component.scss'
})
export class HelpComponent {
  constructor(private headerStateService: HeaderStateService) {
    this.headerStateService.setDetailHeader('Hilfe');
  }
}
