import { Component } from '@angular/core';
import { HeaderStateService } from '../../headers/services/header-state.service';
import { InformationService } from '../services/information-service';
import { ImprintModel } from '../model/imprint.model';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrl: './imprint.component.scss',
})
export class ImprintComponent {
  public imprintModel?: ImprintModel;

  constructor(
    private headerStateService: HeaderStateService,
    private informationService: InformationService
  ) {
    this.headerStateService.setDetailHeader('Impressum');
  }

  ngOnInit(): void {
    this.loadMessage();
  }

  loadMessage() {
    this.informationService.getImprint().subscribe({
      next: (value) => value.text,
    });
  }
}
