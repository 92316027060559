<!-- Logged in -->
<ng-container *ngIf="isLoggedIn">

  <div class="d-flex flex-column gap-3">
    <app-under-construction></app-under-construction>
    <!-- Upcoming Assignments -->
    <app-page-divider [text]="'home.upcomingAssignments.title' | transloco"></app-page-divider>
    <app-assignments-list [assignmentSubject]="assignmentsStateService.topThreeUpcomingAssignmentSubjects"
      [eventType]="assignmentEventType.RouteToDetails"></app-assignments-list>
    <div class="d-flex justify-content-center">
      <button [routerLink]="'/assignments/my'" class="btn btn-default">
          {{ "home.showMore.title" | transloco }}
      </button>
    </div>

    <!-- Open Assignments -->
    <app-page-divider [text]="'home.openAssignments.title' | transloco"></app-page-divider>
    <app-assignments-list [assignmentSubject]="assignmentsStateService.topThreeOpenAssignmentSubjects"
      [eventType]="assignmentEventType.RouteToDetails"></app-assignments-list>
      <div class="d-flex justify-content-center">
        <button [routerLink]="'/assignments/open'" class="btn btn-default">
            {{ "home.showMore.title" | transloco }}
        </button>
      </div>
  </div>
</ng-container>

<!-- Not logged in -->
<ng-container *ngIf="isLoggedIn != undefined && !isLoggedIn">
  <div class="d-flex flex-column p-3">
    <div class="w-75 mx-auto" style="max-width: 400px;">
      <img class="w-100" src="assets/images/logos/staff/logo_staff_a.png">
    </div>
    <div class="w-75 mx-auto" style="max-width: 400px;">
      <button class="btn btn-default w-100" (click)="login()">
        {{'home.login' | transloco}}
      </button>
    </div>
  </div>
</ng-container>